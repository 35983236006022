
const colors = {
  grey: '#aaa',
  primary: {
    main: '#00796b',
    light: '#79a7b5',
    dark: '#00544a'
  },
  secondary: {
    main: "#ff6488",
  },
}

export default colors
