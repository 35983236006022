import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom"
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { useHistory } from "react-router-dom"



import Home from './components/pages/Home'
import Contact from './components/pages/Contact'
import Faq from './components/pages/Faq'
import Apply from './components/pages/Apply'

import Header from './components/common/Header'
import Loading from './components/common/Loading'



import {Backdrop, CircularProgress, Button} from "@material-ui/core"
//import NavDrawer from './components/NavDrawer/NavDrawer'

//import socketIoClient from "socket.io-client"; // socket.io



import {ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

//const socket = socketIoClient("http://localhost:5000", { autoConnect: false });
//console.log(socket)
import socketIoClient from 'socket.io-client'
import {urlServer} from "./api"

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
//import { purple } from '@material-ui/core/colors';
//import { ThemeProvider } from '@mui/material/styles';
//import { createTheme } from '@mui/material/styles';

//import theme from './themes/_main'
import colors from './themes/colors'

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      //main: "#64ffda",
      main: colors.primary.main,
    },
    secondary: {
      // This is green.A700 as hex.
      main: colors.secondary.main,
    },
  },
});


const useStyles = makeStyles({
  contentStyle: {
    margin: "30px auto",
  },
})

function Views(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  //const auth = useSelector(state => state.auth)
  const appState = useSelector(state => state.app)
  //const [connected, setConnected] = useState(false)
  //export setConnected

  window.addEventListener("storage", function(event)  {
    if (event.key === "token") {
      console.log("XAUTHROKEN")
    }
  })

  //socket.connect()

  //const [socket, setSocket] = React.useState(null);

  useEffect(() => {



  //}, [dispatch])
}, [])

  return (
    <ThemeProvider theme={theme}>
      <Loading />
      <BrowserRouter>
        <ToastContainer/>

        <Container maxWidth={false} disableGutters={true}>

          <Header/>
          <React.StrictMode>
            <Container className={classes.contentStyle} maxWidth="sm">
              <Switch>
                <Route
                  path="/" exact
                  //component={SignIn}
                  render={() => <Home/>}
                />
                <Route
                  path="/apply" exact
                  //component={SignIn}
                  render={() => <Apply/>}
                />
                <Route
                  path="/contact" exact
                  //component={SignIn}
                  render={() => <Contact/>}
                />
                <Route
                  path="/faq" exact
                  //component={Todos} soxet={numIn}
                  render={() => <Faq/>}
                />
                {/*<Redirect to="/" />*/}
              </Switch>
            </Container>
          </React.StrictMode>
        </Container>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default Views;
