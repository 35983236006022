const inputLimits = {
  email: {
    max: 40,
  },
  key1: 8,
  key2: 16,
  message: {
    max: 200,
  },
  name: {
    max: 30,
  },
  password: {
    min: 6,
    max: 64,
  }
}

export default inputLimits
