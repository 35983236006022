import {Typography, TextField, Button, Box} from "@material-ui/core"


import AccordionDetails from '@mui/material/AccordionDetails';

import AccordionSummary from '../../styled/AccordionSummary'


const RewardsType = (props) => {


  return ( <>
    <Typography>
      Yes, absolutely.
      I do not make you sign any contract when you work with me and I do not restrict you to only finding work through me.
    </Typography>
  </> )
}

export default RewardsType
