import {makeStyles} from "@material-ui/styles"
import colors from './colors'
import theme from './_main'

export const useStyles = makeStyles({
  blockFull: {
    //backgroundColor: colors.primary.main,
    position: "absolute",
    left: 0,
    width: "100%",
    flexGrow: 1,
  },
  blockPrimary: {
    backgroundColor: colors.primary.main,
    position: "absolute",
    left: 0,
    width: "100%",
    //width: "100vw",
  },
  clickBlack: {
    //cursor: "pointer",
    color: "#000",
    'text-decoration': 'none',
    "&:hover": {
      cursor: "pointer",
    }
  },
  clickGrey: {
    //cursor: "pointer",
    color: colors.grey,
    "&:hover": {
      cursor: "pointer",
    }
  },
  clickWhite: {
    //cursor: "pointer",
    color: "#fff",
    'text-decoration': 'none',
    "&:hover": {
      cursor: "pointer",
    }
  },
  colorLight: {
    backgroundColor: colors.primary.light,
    color: "#fff",
    "&:hover": {
      backgroundColor: colors.primary.dark,
    }
  },
  divider: {
    margin: "10px"
  },
  dotActive: {
    backgroundColor: colors.primary.main
  },
  error: {
    color: "#f44336",
    "font-size": "0.75rem",
  },
  flexGrow: {
    "flex-grow": 1
  },
  fontBold: {
    "font-weight": 900,
  },
  fontItalic: {
    "font-style": "italic",
  },
  fontScript: {
    "font-family": "'Dancing Script', cursive;",
    "font-weight": 900,
  },
  image: {
    //"max-width": theme.breakpoints.values.sm,
    width: "100%",
  },
  linkStyle: {
    //color: "#fafafa",
    color: "white",
    textDecoration: "none",
  },
  root: {
    backgroundColor: "#fff"
  },
  shadowBox: {
    margin: "0px auto",
    padding: "30px",
    borderRadius: "9px",
    boxShadow: "0px 0px 12px -3px #000",
  },
  spacing: {
    marginTop: "20px",
  },
  spacingHalf: {
    marginTop: "10px",
  },
  spacingMinus: {
    marginTop: "-20px",
  },
  spacingLeft: {
    "margin-left": "10px",
  },
  spacingRight: {
    "margin-right": "10px",
  },
  spreadBox: {
    display: "flex",
    width: "100%",
  },
  spreadBoxCenter: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  spreadBoxFull: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    //color: "#d00"
  },
  stretch: {
    height: "100%"
  },
  tableAlignTop: {
    "vertical-align": "top",
    "padding-top": "10px",
  },
  tableColumnSpacing: {
    "padding-right": "10px",
  },
  textGrey: {
    color: colors.grey,
  },
  textNeg: {
    color: colors.secondary.main
  },
  textPos: {
    color: colors.primary.main
  },
})

//export default useStyles
