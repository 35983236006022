import React, {useState} from 'react'
//import { Image } from 'react-native';
// useState helps us get form values
import {useDispatch, useSelector} from "react-redux"
// useDispatch will help us dispatch signIn action creator
/// useSelector will help us to select a certain piece of state from redux store
import {Redirect, Link} from "react-router-dom"
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import {loadingToggle} from "../../store/actions/appActions"


import {Typography, TextField, Button, Box} from "@material-ui/core"
import {makeStyles} from "@material-ui/styles"

import {useStyles} from '../../themes/styles'
//import useStyles from '../../themes/styles'
import {colors} from '../../themes/colors'

import img_cards from '../../images/cards_02.jpg'
import img_travel from '../../images/travel_01.jpg'

import theme from '../../themes/_main'
import useMediaQuery from '@mui/material/useMediaQuery';

const Home = (props) => {
  const classes = useStyles()

  return ( <>
    <Box className={classes.spacing}>
      <Typography variant="h2" align="center" className={classes.fontScript}>M.A.S. Talent Connections</Typography>
      <Typography variant="h4" align="center" className={classes.fontScript0}>100% Free Talent Representation</Typography>
      <Typography variant="h5" align="center" className={classes.fontScript0}>Modeling. Acting. Singing.</Typography>
    </Box>
    <Box className={classes.spreadBoxCenter}>
      <Link to="/apply" className={classes.clickWhite}>
        <Button
          className={classes.spacing}
          variant="contained"
          color="primary"
        >
          Apply
        </Button>
      </Link>
    </Box>
    <Box className={classes.spacing + " " + classes.shadowBox}>
      <Typography variant="h5">
        Hi!
        <br/>
        <br/>
        My name is Ben.
        The pandemic has encouraged me to start a new career.
        When I was younger I had the opportunity to take part in some productions for Disney and Universal.
        I'm looking to take what I learned and turn that into a talent representation business.
        <br/>
        <br/>
        For the first year, I am guaranteeing 100% commission-free representation for all talent, with no experience required.
        If you would like to apply for representation, please {" "}<Link to="/apply">apply here</Link>.
        <br/>
        <br/>
        To learn more, please visit our {" "}<Link to="/faq">Frequently Asked Questions</Link>.
      </Typography>
    </Box>



  </> )
}

export default Home
