import React from 'react'
import {useDispatch, useSelector} from "react-redux"

import { AppBar, Typography, Toolbar } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles"

import { Link, useHistory } from "react-router-dom"

// https://www.geeksforgeeks.org/how-to-make-drawer-using-material-ui/
import { Drawer, Divider, IconButton } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import IconHome from '@material-ui/icons/Home'
import IconNewAnalysis from '@material-ui/icons/CreditCard'
import IconHistory from '@material-ui/icons/AccessTime'
import IconAccount from '@material-ui/icons/AccountCircle'
import IconLogout from '@material-ui/icons/ExitToApp'
import IconFAQ from '@material-ui/icons/HelpOutline'

import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';

//import { useTheme } from '@mui/material/styles';


//import Loading from './Loading'

const useStyles = makeStyles({
  fullList: {
    width: 'auto',
  },
  linkStyle: {
    //color: "#fafafa",
    color: "white",
    textDecoration: "none",
  },
  list: {
    width: 250,
  },
  name: {
    "text-align": "right",
  },
  root: {
    flexGrow: 1,
    //"text-align": "center",
    "padding-left": "10px",
  },
});

const Header = (props) => {
  const classes = useStyles()
  //const theme = useTheme();
  //const state = useSelector(state => state)
  const auth = useSelector(state => state.auth)
  const history = useHistory()
  const dispatch = useDispatch()

  const [state, setState] = React.useState({
    drawerOpen: false,
  });

  const [activeStep, setActiveStep] = React.useState(0);

  const toggleDrawer = (newState) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, drawerOpen: newState });
  };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon><IconHome/></ListItemIcon>
          <ListItemText primary="Home"/>
        </ListItem>
        <ListItem button component={Link} to="/faq">
          <ListItemIcon><IconFAQ/></ListItemIcon>
          <ListItemText primary="FAQ" />
        </ListItem>

      </List>
      <Divider/>
      <List>
        <ListItem button component={Link} to="/apply">
          <ListItemIcon><IconAccount/></ListItemIcon>
          <ListItemText primary="Apply"/>
        </ListItem>

      </List>
    </div>
  );

  return ( <>
    <Drawer anchor="left" open={state["drawerOpen"]} onClose={toggleDrawer(false)}>
      {list()}
    </Drawer>
    <AppBar position="static" width="100%">
      <Toolbar>
        <IconButton onClick={toggleDrawer(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Typography variant="h5" className = {classes.root}>
          <Link to="/" className={classes.linkStyle}>
            M.A.S. Talent Connections
          </Link>
        </Typography>
      </Toolbar>

    </AppBar>
  </> )
}

export default Header
