import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Redirect} from "react-router-dom"
import { Link, useHistory, useLocation } from "react-router-dom"

import Stack from '@mui/material/Stack';
import {Typography, TextField, Button, Box} from "@material-ui/core"
import {makeStyles, ThemeProvider} from "@material-ui/styles"

import themeInputField from "../../themes/inputField"

import {loadingToggle} from "../../store/actions/appActions"

import inputLimits from '../../constants/inputLimits'

import {useStyles} from '../../themes/styles'

import axios from "axios"
import {urlApi} from "../../api"
import {toast} from "react-toastify"

export default (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const [message, setMessage] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const changeVal = (event, typeLimit, typeUser) => {
    if (!typeUser) {
      var typeUser = typeLimit
    }
    var val = event.target.value
    const max = inputLimits[typeLimit].max
    if (val.length > max) {
      val = val.slice(0, max)
    }
    //inputs[typeUser] = val
    setMessage(val)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    console.log("submit")
    dispatch(loadingToggle(true))
    axios
    .post(`${urlApi}/user/contact`, {message}, {"withCredentials": true})
    .then((res) => {
      console.log(res)
      setSubmitted(true)
      //console.log(socket)
      //localStorage.setItem("name", user.name)
      //socket.emit('authUser')
      dispatch(loadingToggle(false))

    })
    .catch(error => {
      console.log(error.response)
      toast.error(error.response?.data, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
    })

  }



  if (submitted) {
    return <Typography variant="h6" className={classes.shadowBox}>
      Thanks for contacting us. We will get back to you as quickly as we can. Please keep an eye out for an email from us.
    </Typography>
  }

  return ( <>

    <form
    className={classes.shadowBox}
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}
    >
      <Typography variant="h4" sx={{}}>Contact Us</Typography>

      <Box className={classes.spacing}>
        <TextField
          id="enter-message"
          label="Message"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={message}
          onChange = {(event) => {
            changeVal(event, "message")
          }}
        />
        <Typography variant="body1">{message.length} / {inputLimits.message.max}</Typography>
        <Button
          className={classes.spacing}
          variant="contained"
          color="primary"
          type="submit"
          disabled={!Boolean(message.length)}
        >
          Submit
        </Button>
      </Box>
    </form>
  </> )
}
