

export const appConnected = (isLoading) => {
  console.log("appConnected")
  return (dispatch) => {
    dispatch({
      type: "APP_CONNECTED",
    })
  }
}

export const loadingToggle = (isLoading) => {
  return (dispatch) => {
    dispatch({
      type: "LOADING_TOGGLE",
      isLoading,
    })
  }
}
