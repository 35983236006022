import {Typography, TextField, Button, Box} from "@material-ui/core"


import AccordionDetails from '@mui/material/AccordionDetails';

import AccordionSummary from '../../styled/AccordionSummary'


const Cost = (props) => {

  console.log(props)

  return ( <>
    <Typography>
      It will cost you absolutely nothing to sign up.
      <br/>
      <br/>
      If you need to create a demo or profile, this cost will be 100% covered for you.
    </Typography>
  </> )
}

export default Cost
