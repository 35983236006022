import React, {useEffect, useState} from 'react'
// useState helps us get form values

import Accordion from '../styled/Accordion'

import Faq_commission from './faqViews/commission'
import Faq_cost from './faqViews/cost'
import Faq_experience from './faqViews/experience'
import Faq_exclusivity from './faqViews/exclusivity'
import Faq_pay from './faqViews/pay'

import AccordionDetails from '@mui/material/AccordionDetails';

import AccordionSummary from '../styled/AccordionSummary'

import {Typography, TextField, Button, Box} from "@material-ui/core"
import {makeStyles} from "@material-ui/styles"

import {useStyles} from '../../themes/styles'

const faqs = [
  {
    name: "cost",
    question: "How much will it cost me to sign up?",
    response: Faq_cost,
  },
  {
    name: "commission",
    question: "Do you really not take a commission?",
    response: Faq_commission,
  },
  {
    name: "pay",
    question: "Will I get any paying jobs?",
    response: Faq_pay,
  },
  {
    name: "experience",
    question: "I don't have any experience. Is that okay?",
    response: Faq_experience,
  },
  {
    name: "exclusivity",
    question: "Am I allowed to sign with another talent agency?",
    response: Faq_exclusivity,
  },
]

const Faq = (props) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      //behavior: "smooth",
    })
  }, [])

  return ( <>
    <Typography variant="h4" sx={{}}>Frequently Asked Questions</Typography>
    <div className={classes.spacing}>
    {faqs.map((step, index) => (
      <Accordion expanded={expanded === index} onChange={handleChange(index)}>
        <AccordionSummary aria-controls={`panel-content_${step.name}`} id={`panel-header_${step.name}`}>
          <Typography>{step.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
          component={step.response}
          />
        </AccordionDetails>
      </Accordion>
    ))}
    </div>
  </> )
}

export default Faq
