import {Typography, TextField, Button, Box} from "@material-ui/core"


import AccordionDetails from '@mui/material/AccordionDetails';

import AccordionSummary from '../../styled/AccordionSummary'


const Comission = (props) => {

  console.log(props)

  return ( <>
    <Typography>
      That's correct.
      At this point it would be illegal for me to take any commission.
      The State of Florida requires that for a Talent Manager to take a commission they need to be licensed.
      I am currently working on getting my license (which will take a minimum of one year), and until I do, I cannot accept any sort of commission.
      100% of the pay will go directly to you.
    </Typography>
  </> )
}

export default Comission
