import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Redirect} from "react-router-dom"
import { Link, useHistory, useLocation } from "react-router-dom"
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

import Stack from '@mui/material/Stack';
import {Typography, TextField, Button, Box} from "@material-ui/core"
import {makeStyles, ThemeProvider} from "@material-ui/styles"

import themeInputField from "../../themes/inputField"

import {loadingToggle} from "../../store/actions/appActions"

import inputLimits from '../../constants/inputLimits'
import isEmail from '../../fns/isEmail'

import {useStyles} from '../../themes/styles'

import axios from "axios"
import {urlApi} from "../../api"
import {toast} from "react-toastify"

import theme from '../../themes/_main'
import useMediaQuery from '@mui/material/useMediaQuery';


const useQuery = () => {
  const {search} = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const query = useQuery()
  const fields = {
    nameFirst: "",
    nameLast: "",
    age: "",
    gender: "",
    city: "",
    email: "",
    socialMedia: "",
    bio: "",
  }
  const optionsModeling = [
    {
      cc: "cosplay",
      disp: "Cosplay",
    },
    {
      cc: "fashion",
      disp: "Fashion",
    },
    {
      cc: "fitness",
      disp: "Fitness",
    },
    {
      cc: "lingerie",
      disp: "Lingerie",
    },
    {
      cc: "pinUp",
      disp: "Pin-up",
    },
    {
      cc: "promotional",
      disp: "Promotional",
    },
    {
      cc: "swimwear",
      disp: "Swimwear",
    },
    {
      cc: "nudeImplied",
      disp: "Implied Nude",
    },
    {
      cc: "nudePartial",
      disp: "Partial Nude (Artistic)",
    },
    {
      cc: "nudeFullArtistic",
      disp: "Full Nude (Artistic)",
    },
    {
      cc: "nudeFullErotic",
      disp: "Full Nude (Erotic)",
    },
  ]
  const optionsActing = [
    {
      cc: "live",
      disp: "Live Productions",
    },
    {
      cc: "recorded",
      disp: "Recorded Productions",
    },
  ]
  const optionsSinging = [
    {
      cc: "sopranno",
      disp: "Sopranno",
    },
    {
      cc: "mezzoSopranno",
      disp: "Mezzo-Sopranno",
    },
    {
      cc: "alto",
      disp: "Alto",
    },
    {
      cc: "tenor",
      disp: "Tenor",
    },
    {
      cc: "baritone",
      disp: "Baritone",
    },
    {
      cc: "bass",
      disp: "Bass",
    },

  ]
  const [inputs, setInputs] = useState(fields)
  const [errors, setErrors] = useState(fields)
  const [talents, setTalents] = useState({
    modeling: false,
    acting: false,
    singing: false,
  })
  const [errorsTalents, setErrorsTalents] = useState({
    talent: false,
    modeling: false,
    acting: false,
    singing: false,
  })
  const [submitted, setSubmitted] = useState(false)
  let selectedModeling = {}
  for (let iModeling = 0; iModeling < optionsModeling.length; iModeling++) {
    selectedModeling[optionsModeling[iModeling].cc] = false
  }
  const [modeling, setModeling] = useState(selectedModeling)
  let selectedActing = {}
  for (let iActing = 0; iActing < optionsActing.length; iActing++) {
    selectedActing[optionsActing[iActing].cc] = false
  }
  const [acting, setActing] = useState(selectedActing)
  let selectedSinging = {}
  for (let iSinging = 0; iSinging < optionsSinging.length; iSinging++) {
    selectedSinging[optionsSinging[iSinging].cc] = false
  }
  const [singing, setSinging] = useState(selectedSinging)

  const desktop = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);

  const arrToStrTalents = () => {
    var str = ""
    if (talents.modeling) {
      str += "Modeling, "
    }
    if (talents.acting) {
      str += "Acting, "
    }
    if (talents.singing) {
      str += "Singing, "
    }
    str = str.substring(0, str.length -2)
    return str
  }

  const arrToStrModeling = () => {
    var str = ""
    for (var i = 0; i < optionsModeling.length; i++) {
      if (modeling[optionsModeling[i].cc]) {
        str += (optionsModeling[i].disp + ", ")
      }
    }
    str = str.substring(0, str.length -2)
    if (!str) {
      return "N/A"
    }
    return str
  }

  const arrToStrActing = () => {
    var str = ""
    for (var i = 0; i < optionsActing.length; i++) {
      if (acting[optionsActing[i].cc]) {
        str += (optionsActing[i].disp + ", ")
      }
    }
    str = str.substring(0, str.length -2)
    if (!str) {
      return "N/A"
    }
    return str
  }

  const arrToStrSinging = () => {
    var str = ""
    for (var i = 0; i < optionsSinging.length; i++) {
      if (singing[optionsSinging[i].cc]) {
        str += (optionsSinging[i].disp + ", ")
      }
    }
    str = str.substring(0, str.length -2)
    if (!str) {
      return "N/A"
    }
    return str
  }

  const handleChangeTalent = (cc, event) => {
    talents[cc] = event.target.checked
    setTalents({...talents})
    validateSelectTalent()
  }

  const handleChangeModeling = (cc, event) => {
    modeling[cc] = event.target.checked
    setModeling({...modeling})
    validateSelectModeling()
  }

  const handleChangeActing = (cc, event) => {
    acting[cc] = event.target.checked
    setActing({...acting})
    validateSelectActing()
  }

  const handleChangeSinging = (cc, event) => {
    singing[cc] = event.target.checked
    setSinging({...singing})
    validateSelectSinging()
  }

  const validateAge = () => {
    var error = ""
    if (!inputs.age.length) {
      var error = "Please enter your age"
    }
    setErrors({...errors, age: error})
    return error
  }

  const validateBio = () => {
    var error = ""
    if (!inputs.bio.length) {
      var error = "Please let us know a little bit about you"
    }
    setErrors({...errors, bio: error})
    return error
  }

  const validateCity = () => {
    var error = ""
    if (!inputs.city.length) {
      var error = "Please enter your city"
    }
    setErrors({...errors, city: error})
    return error
  }

  const validateEmail = () => {
    var error = ""
    if (!inputs.email.length) {
      var error = "Please enter your email"
    }
    else if (!isEmail(inputs.email)) {
      var error = "Please enter a valid email address"
    }
    setErrors({...errors, email: error})
    return error
  }

  const validateGender = () => {
    var error = ""
    if (!inputs.gender.length) {
      var error = "Please enter your gender"
    }
    setErrors({...errors, gender: error})
    return error
  }

  const validateNameFirst = () => {
    var error = ""
    if (!inputs.nameFirst.length) {
      var error = "Please enter your first name"
    }
    setErrors({...errors, nameFirst: error})
    return error
  }

  const validateNameLast = () => {
    var error = ""
    if (!inputs.nameLast.length) {
      var error = "Please enter your last name"
    }
    setErrors({...errors, nameLast: error,})
    return error
  }

  const validateSelectTalent = () => {
    var error = true
    if (talents.modeling || talents.acting || talents.singing) {
      var error = false
    }
    setErrorsTalents({
      ...errorsTalents,
      talent: error
    })
    return error
  }

  const validateSelectModeling = () => {
    if (talents.modeling) {
      var error = true
      for (var i = 0; i < optionsModeling.length; i++) {
        if (modeling[optionsModeling[i].cc]) {
          var error = false
          break
        }
      }
    }
    else {
      var error = false
    }
    setErrorsTalents({
      ...errorsTalents,
      modeling: error
    })
    return error
  }

  const validateSelectActing = () => {
    if (talents.acting) {
      var error = true
      for (var i = 0; i < optionsActing.length; i++) {
        if (acting[optionsActing[i].cc]) {
          var error = false
          break
        }
      }
    }
    else {
      var error = false
    }
    setErrorsTalents({
      ...errorsTalents,
      acting: error
    })
    return error
  }

  const validateSelectSinging = () => {
    if (talents.singing) {
      var error = true
      for (var i = 0; i < optionsSinging.length; i++) {
        if (singing[optionsSinging[i].cc]) {
          var error = false
          break
        }
      }
    }
    else {
      var error = false
    }
    setErrorsTalents({
      ...errorsTalents,
      singing: error
    })
    return error
  }

  /*const validateName = () => {
    var error = ""
    if (!user.name.length) {
      var error = "Please enter your name"
    }
    setErrors({
      ...errors,
      name: error,
    })
    return error
  }*/

  /*const changeVal = (event, typeLimit, typeUser) => {
    if (!typeUser) {
      var typeUser = typeLimit
    }
    var val = event.target.value
    const max = inputLimits[typeLimit].max
    if (val.length > max) {
      val = val.slice(0, max)
    }
    //inputs[typeUser] = val
    setEmail(val)
  }*/

  const handleSubmit = async (event) => {
    event.preventDefault()
    dispatch(loadingToggle(true))
    const errorNameFirst = await validateNameFirst()
    const errorNameLast = await validateNameLast()
    const errorAge = await validateAge()
    const errorGender = await validateGender()
    const errorCity = await validateCity()
    const errorEmail = await validateEmail()
    const errorBio = await validateBio()
    const errorTalent = await validateSelectTalent()
    const errorModeling = await validateSelectModeling()
    const errorActing = await validateSelectActing()
    const errorSinging = await validateSelectSinging()
    if (
      errorNameFirst
    ||
      errorNameLast
    ||
      errorAge
    ||
      errorGender
    ||
      errorCity
    ||
      errorEmail
    ||
      errorBio
    ||
      errorTalent
    ||
      errorModeling
    ||
      errorActing
    ||
      errorSinging
    ) {
      setErrors({
        ...errors,
        nameFirst: errorNameFirst,
        nameLast: errorNameLast,
        age: errorAge,
        gender: errorGender,
        city: errorCity,
        email: errorEmail,
        bio: errorBio,
      })
      setErrorsTalents({
        talent: errorTalent,
        modeling: errorModeling,
        acting: errorActing,
        singing: errorSinging,
      })
      dispatch(loadingToggle(false))
    }
    else {
      const reqBody = {
        inputs,
        talents: arrToStrTalents(),
        modeling: arrToStrModeling(),
        acting: arrToStrActing(),
        singing: arrToStrSinging(),
      }
      axios
      .post(`${urlApi}/apply`, reqBody, {"withCredentials": true})
      .then((res) => {
        console.log(res)
        setSubmitted(true)
        //console.log(socket)
        //localStorage.setItem("name", user.name)
        //socket.emit('authUser')
        dispatch(loadingToggle(false))

      })
      .catch(error => {
        console.log(error.response)
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
        dispatch({
          type: "LOADING_TOGGLE",
          isLoading: false,
        })
      })
      //dispatch(pwReset(inputs, props.socket))
      //setInputs()
       // TODO: delete this line after dispatch created
    }

  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      //behavior: "smooth",
    })
  }, [])

  if (submitted) {
    return <Typography variant="h6" className={classes.shadowBox}>
      Thank you for your application. Please keep an eye on your email for a response from us.
    </Typography>
  }

  return ( <>

    <form
    className={classes.shadowBox}
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}
    >
      <Typography variant="h4" sx={{}}>Apply</Typography>
      <Box className={classes.spacing}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            id="nameFirst"
            className={classes.spacing0}
            label="First Name"
            variant="outlined"
            fullWidth
            value={inputs.nameFirst}
            onChange = {(event) => setInputs({...inputs, nameFirst: event.target.value})}
            error={Boolean(errors.nameFirst)}
            helperText={errors.nameFirst}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            id="nameLast"
            className={classes.spacing0}
            label="Last Name"
            variant="outlined"
            fullWidth
            value={inputs.nameLast}
            onChange = {(event) => setInputs({...inputs, nameLast: event.target.value})}
            error={Boolean(errors.nameLast)}
            helperText={errors.nameLast}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            id="age"
            className={classes.spacing0}
            label="Age"
            variant="outlined"
            fullWidth
            value={inputs.age}
            onChange = {(event) => setInputs({...inputs, age: event.target.value})}
            error={Boolean(errors.age)}
            helperText={errors.age}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            id="gender"
            className={classes.spacing0}
            label="Gender"
            variant="outlined"
            fullWidth
            value={inputs.gender}
            onChange = {(event) => setInputs({...inputs, gender: event.target.value})}
            error={Boolean(errors.gender)}
            helperText={errors.gender}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            id="city"
            className={classes.spacing0}
            label="City"
            variant="outlined"
            fullWidth
            value={inputs.city}
            onChange = {(event) => setInputs({...inputs, city: event.target.value})}
            error={Boolean(errors.city)}
            helperText={errors.city}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            id="email"
            className={classes.spacing0}
            label="Email"
            variant="outlined"
            fullWidth
            value={inputs.email}
            onChange = {(event) => setInputs({...inputs, email: event.target.value})}
            error={Boolean(errors.email)}
            helperText={errors.email}
            onBlur={() => validateEmail()}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="socialMedia"
            label="Social Media Links"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={inputs.socialMedia}
            onChange = {(event) => setInputs({...inputs, socialMedia: event.target.value})}
            error={Boolean(errors.socialMedia)}
            helperText={errors.socialMedia}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="bio"
            label="Bio (Experience, Goals, Etc.)"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={inputs.bio}
            onChange = {(event) => setInputs({...inputs, bio: event.target.value})}
            error={Boolean(errors.bio)}
            helperText={errors.bio}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" error={errorsTalents.talent}>
            <FormLabel component="legend">Areas of Interest</FormLabel>
            <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={talents.modeling} onChange={(event) => handleChangeTalent("modeling", event)} name="modeling" />
              }
              label="Modeling"
            />
            <FormControlLabel
              control={
                <Checkbox checked={talents.acting} onChange={(event) => handleChangeTalent("acting", event)} name="acting" />
              }
              label="Acting"
            />
            <FormControlLabel
              control={
                <Checkbox checked={talents.singing} onChange={(event) => handleChangeTalent("singing", event)} name="singing" />
              }
              label="Singing"
            />
            </FormGroup>
            {errorsTalents.talent ?
              <FormHelperText>Please select at least one talent</FormHelperText>
            :
              null
            }
          </FormControl>
        </Grid>
        {talents.modeling ?
          <Grid item xs={12}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" error={errorsTalents.modeling}>
              <FormLabel component="legend">Modeling Options</FormLabel>
              <FormGroup>
                {optionsModeling.map((step, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox checked={modeling[step.cc]} onChange={(event) => handleChangeModeling(step.cc, event)} name={step.cc} />
                    }
                    label={step.disp}
                    key={step.cc}
                  />
                ))}
              </FormGroup>
              {errorsTalents.modeling ?
                <FormHelperText>Please select at least one Modeling option</FormHelperText>
              :
                null
              }
            </FormControl>
          </Grid>
        :
          null
        }
        {talents.acting ?
          <Grid item xs={12}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormLabel component="legend">Acting Options</FormLabel>
              <FormGroup>
                {optionsActing.map((step, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox checked={acting[step.cc]} onChange={(event) => handleChangeActing(step.cc, event)} name={step.cc} />
                    }
                    label={step.disp}
                    key={step.cc}
                  />
                ))}
              </FormGroup>
              {errorsTalents.acting ?
                <FormHelperText>Please select at least one Acting option</FormHelperText>
              :
                null
              }
            </FormControl>
          </Grid>
        :
          null
        }
        {talents.singing ?
          <Grid item xs={12}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormLabel component="legend">Singing Options</FormLabel>
              <FormGroup>
                {optionsSinging.map((step, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox checked={singing[step.cc]} onChange={(event) => handleChangeSinging(step.cc, event)} name={step.cc} />
                    }
                    label={step.disp}
                    key={step.cc}
                  />
                ))}
              </FormGroup>
              {errorsTalents.singing ?
                <FormHelperText>Please select at least one Singing option</FormHelperText>
              :
                null
              }
            </FormControl>
          </Grid>
        :
          null
        }

      </Grid>
      </Box>
      <Box className={classes.spreadBoxCenter}>
        <Button
          className={classes.spacing}
          variant="contained"
          color="primary"
          type="submit"
        >
          Submit Application
        </Button>
      </Box>
    </form>
  </> )
}
