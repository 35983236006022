import * as React from 'react';
import {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
//import Backdrop from '@mui/material/Backdrop';
//import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';
import { makeStyles } from "@material-ui/core/styles"

import {Backdrop, CircularProgress, Button} from "@material-ui/core"

const useStyles = makeStyles({
  backdrop: {
    zIndex: 9999,
  },
});

const Loading = () => {
  const classes = useStyles()
  const loading = useSelector(state => state.app.loading)

//export default function SimpleBackdrop() {
  /*const [loading, setLoading] = React.useState(false);
  const handleClose = () => {
    setLoading(false);
  };
  const handleToggle = () => {
    setLoading(!loading);
  };*/

  return (
    <div>
      <Backdrop

        className={classes.backdrop}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Loading
