export const urlServer = 'https://mas-talent-fl.herokuapp.com'

export const urlApi = (urlServer + "/api")

export const setHeaders = () => {
  const header = {
    headers: {
      //"x-auth-token": localStorage.getItem("token")
    }
  }

  return header
}
