//import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import colors from './colors'

export default createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      //main: "#64ffda",
      main: colors.primary.main,
    },
    secondary: {
      // This is green.A700 as hex.
      main: colors.secondary.main,
    },
  },
});
