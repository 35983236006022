import {Typography, TextField, Button, Box} from "@material-ui/core"


import AccordionDetails from '@mui/material/AccordionDetails';

import AccordionSummary from '../../styled/AccordionSummary'


const Experience = (props) => {

  console.log(props)

  return ( <>
    <Typography>
      Yes, that is perfectly fine.
      Everybody has to start off somewhere.
      If you don't have any experience you may need to set up a demo or profile.
      I will organize this and cover any costs.
    </Typography>
  </> )
}

export default Experience
