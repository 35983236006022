import {Typography, TextField, Button, Box} from "@material-ui/core"


import AccordionDetails from '@mui/material/AccordionDetails';

import AccordionSummary from '../../styled/AccordionSummary'


const CreditScore = (props) => {


  return ( <>
    <Typography>
      I do not guarantee that anybody I represent will get any paid jobs.
      There are certainly paid jobs out there, but whether you get them will depend on the specific jobs.
    </Typography>
  </> )
}

export default CreditScore
